import clsx from "clsx"
import React from "react"

const YoutubeIcon = ({ className, outline }) => {
  return outline ? (
    <svg
      height="32"
      width="32"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 461.001 461.001"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="white"
          d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
   c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
   C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
   c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
        />
      </g>
    </svg>
  ) : (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("youtube-icon", className)}
    >
      <g clipPath="url(#clip0_4634_19032)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.848 10.09L6.8475 5.56259L11.1705 7.8341L6.848 10.09ZM16.34 4.77807C16.34 4.77807 16.1835 3.66775 15.704 3.1788C15.0955 2.53678 14.4135 2.53375 14.101 2.49649C11.862 2.33334 8.5035 2.33334 8.5035 2.33334H8.4965C8.4965 2.33334 5.138 2.33334 2.899 2.49649C2.586 2.53375 1.9045 2.53678 1.2955 3.1788C0.816002 3.66775 0.659999 4.77807 0.659999 4.77807C0.659999 4.77807 0.5 6.08225 0.5 7.38593V8.60855C0.5 9.91274 0.659999 11.2164 0.659999 11.2164C0.659999 11.2164 0.816002 12.3267 1.2955 12.8157C1.9045 13.4577 2.704 13.4376 3.06 13.5045C4.34 13.6284 8.5 13.6667 8.5 13.6667C8.5 13.6667 11.862 13.6616 14.101 13.4985C14.4135 13.4607 15.0955 13.4577 15.704 12.8157C16.1835 12.3267 16.34 11.2164 16.34 11.2164C16.34 11.2164 16.5 9.91274 16.5 8.60855V7.38593C16.5 6.08225 16.34 4.77807 16.34 4.77807Z"
          fill="#CE1312"
        />
      </g>
      <defs>
        <clipPath id="clip0_4634_19032">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default YoutubeIcon
