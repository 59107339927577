import NewsCard from "@components/NewsCard/NewsCard"
import Pagination from "@components/Pagination/Pagination"
import CustomSelect from "@components/elements/CustomSelect/CustomSelect"
import { Tab, Tabs } from "@components/elements/Tabs/Tabs"
import useBlogs from "@hooks/dataHooks/useBlogs"
import useDeviceMedia from "@hooks/useDeviceMedia"
import usePagination from "@hooks/usePagination"
import { capitalize, isEmpty } from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import "./NewsListing.scss"
import { Link } from "gatsby"
import YoutubeIcon from "@components/icons/YoutubeIcon"
import { abbreviateNumber } from "@lib/utils"
import { useSubscriberStore } from "@lib/subscriberStore"
import { useSessionStorage } from "@hooks/useSessionStorage"

const getYtData = async () => {
  try {
    const response = await fetch(
      `https://youtube.googleapis.com/youtube/v3/channels?part=statistics&id=UCtkxUTV18L4YsvIYfmRt_Lg&key=${process.env.GATSBY_YOUTUBE_API_KEY}`
    )
    if (!response.ok) {
      throw new Error("Something went wrong")
    }
    const data = await response.json()
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("🚀 NewsListing getYtData ~ error:", error)
    return null
  }
}

export const getCategories = (blogs) => {
  return blogs?.reduce(
    (acc, blog) => {
      const blogCategories = blog.category?.strapi_json_value
      if (!isEmpty(blogCategories)) {
        blogCategories.forEach((category) => {
          if (!acc.find((item) => item.value === category)) {
            acc.push({
              label: capitalize(category),
              value: category,
            })
          }
        })
      }
      return acc
    },
    [{ label: "Blogs", value: "all" }]
  )
}

const NewsListing = () => {
  const { isTablet } = useDeviceMedia()

  const allBlogs = useBlogs()
  const [blogsToShow, setBlogsToShow] = React.useState(allBlogs)

  const { subscribers, setSubscribers } = useSubscriberStore((state) => state)

  React.useEffect(() => {
    getYtData().then((data) => {
      const count = data?.items?.[0]?.statistics?.subscriberCount
      if (count) {
        setSubscribers(abbreviateNumber(count))
      }
    })
  }, [])

  const categories = React.useMemo(() => getCategories(allBlogs), [allBlogs])

  const [selectedCategory, setSelectedCategory] = React.useState(
    categories[0].value
  )

  const [currentPage, setCurrentPage] = useSessionStorage("blog_page", 1)


  const paginationProps = usePagination({
    items: blogsToShow,
    itemsPerPage: 12,
    customCurrentPage: currentPage,
    customSetCurrentPage: setCurrentPage,
  })

  // filter blogs based on selected category
  React.useEffect(() => {
    if (selectedCategory === "all") {
      setBlogsToShow(allBlogs)
    } else {
      setBlogsToShow(
        allBlogs.filter((blog) => {
          const blogCategories = blog.category?.strapi_json_value
          return blogCategories?.includes(selectedCategory)
        })
      )
    }
    // paginationProps.setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, allBlogs])
  // filter blogs based on selected category

  return (
    <div className="blog-listing-wrap listing-wrap">
      <div className="category-section-wrap category-section">
        <Container className="category-section">
          {isTablet ? (
            <CustomSelect
              options={categories}
              placeholder="Select Category"
              value={
                categories.find((item) => item.value === selectedCategory) ||
                categories[0]
              }
              onChange={(option) => {
                setSelectedCategory(option.value)
              }}
            />
          ) : (
            <div className="category-tabs-section">
              <Tabs
                activeKey={selectedCategory}
                setActiveKey={setSelectedCategory}
                className="category-tabs"
              >
                {categories.map((category) => (
                  <Tab
                    eventKey={category.value}
                    title={category.label}
                    key={category.value}
                  >
                    {category.label}
                  </Tab>
                ))}
              </Tabs>
            </div>
          )}
          {subscribers && (
            <div className="yt-sub-section">
              <Link
                to="https://www.youtube.com/@Providentestate"
                target="_blank"
                rel="noreferrer"
                className="button button-gray"
              >
                <span>{subscribers} Subscibers</span>
                <YoutubeIcon />
              </Link>
            </div>
          )}
        </Container>
      </div>
      <Container className="blog-listing-container">
        <div className="blog-category-select-section" />
        <div className="blog-listing-section">
          {paginationProps?.currentItems?.map((blog) => (
            <NewsCard key={blog.id} news={blog} noAnimation />
          ))}
        </div>
        <Pagination {...paginationProps} />
      </Container>
    </div>
  )
}

export default NewsListing
